/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Hairline'),
  url(./assets/fonts/MarkPro/MarkPro-Hairline.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Thin'),
  url(./assets/fonts/MarkPro/MarkPro-Thin.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-ExtraLight'),
  url(./assets/fonts/MarkPro/MarkPro-ExtraLight.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro'),
  url(./assets/fonts/MarkPro/MarkPro.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Book'),
  url(./assets/fonts/MarkPro/MarkPro-Book.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Medium'),
  url(./assets/fonts/MarkPro/MarkPro-Medium.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Bold'),
  url(./assets/fonts/MarkPro/MarkPro-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Heavy'),
  url(./assets/fonts/MarkPro/MarkPro-Heavy.ttf) format('truetype');
  font-weight: 800;
}


@font-face {
  font-family: 'MarkPro';
  src: local('MarkPro-Black'),
  url(./assets/fonts/MarkPro/MarkPro-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Thin'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Thin.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Light'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Regular'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Medium'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Bold'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSansJP';
  src: local('NotoSansJP-Black'),
  url(./assets/fonts/NotoSansJP/NotoSansJP-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-ExtraLight'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Light'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Regular'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Medium'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Bold'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Bold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP-Black'),
  url(./assets/fonts/NotoSerifJP/NotoSerifJP-Black.ttf) format('truetype');
  font-weight: 900;
}

html,
body {
  height: 100%;
  font-size: 10px;
}

body {
  margin: 0;
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

/***********************************
            FONTS
***********************************/

* {
  font-family: MarkPro, sans-serif;
}

.lang-ja * {
  font-family: NotoSansJP-Regular, sans-serif;
}

.lang-ja .ja-heading {
  font-family: NotoSerifJP-Regular, sans-serif;
}

.lang-en {
  font-family: MarkPro, sans-serif;
}

.lang-ja .ff-lang-ja-text-en {
  font-family: MarkPro, sans-serif;
}

.material-icons {
  font-family: "Material Icons", sans-serif;
}

/***********************************
          THEME COLORS
***********************************/
.text-color-1 {
  color: #FFB64C;
}

.text-color-2 {
  color: #000000;
}

.text-color-3 {
  color: #FFFFFF;
}

.background-color-1 {
  background: #FFFFFF;
}

.background-color-2 {
  background: #111111;
}

.background-color-3 {
  background: #FFB64C;
}

.background-color-4 {
  background: #F5F5F5;
}

.form-error-message {
  height: 1.35rem;
  font-size: 0.9rem;
  color: #FF0000;
  margin-top: -0.9rem;
  display: flex;
  align-items: center;
  justify-content: start;
}

/******************************
      Notification Popup
*******************************/

.cdk-global-overlay-wrapper {
  z-index: 1001 !important;
}

.mat-mdc-snack-bar-container {
  border-radius: 10px !important;
}

.mat-mdc-snack-bar-container .mat-simple-snackbar span {
  font-size: 17px !important;
  word-break: break-all;
}

.mat-mdc-snack-bar-container.danger .mdc-snackbar__surface {
  background: rgba(255, 0, 0, 1) !important;
}

.mat-mdc-snack-bar-container.warning .mdc-snackbar__surface {
  background: rgba(253, 178, 31, 1) !important;
}

.mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  background: rgba(60, 179, 113, 1) !important;
}

/******************************
          Dialog Box
*******************************/

.mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
}

.cdk-overlay-backdrop-showing:not(.mat-overlay-transparent-backdrop):not(.cdk-overlay-transparent-backdrop) {
  opacity: 1;
  backdrop-filter: blur(2px);
  z-index: 1001;
}

.cdk-overlay-pane .dialog {
  max-height: 100vh;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1002;
}

.mat-mdc-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
  color: #030303 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: unset !important;
}

/******************************
        Loading Screen
*******************************/
.spinner-panel .mat-dialog-container {
  padding: 0 !important;
}

.mat-spinner circle {
  stroke: #FFB64C !important;
}

.spinner-backdrop,
.spinner-backdrop + .cdk-global-overlay-wrapper,
.spinner-panel {
  z-index: 1051 !important;
}

/******************************
        Date Picker
*******************************/
.mat-datetimepicker-calendar-header-year {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.65rem !important;
  text-align: center;
  font-family: MarkPro-Bold, serif;
  margin-bottom: 2.25rem;
}

.mat-datetimepicker-calendar-header-date-time {
  color: #000000;
  font-size: 2.25rem !important;
  text-align: left;
  font-family: MarkPro-Bold, serif;
}

.mat-datetimepicker-calendar-header {
  position: relative;
  z-index: 1;
  overflow: hidden;

}

.mat-datetimepicker-calendar-header::before {
  z-index: -1;
  position: absolute;
  left: -50%;
  bottom: 2rem;
  content: url('./assets/images/png/yellowDiffuserTop.png');
  opacity: 0.5;
}

.mat-datetimepicker-calendar-content {
  padding: 0 !important;
}

.mat-datetimepicker-calendar-controls {
  background: #F1F1F1;
}

.mat-datetimepicker-calendar-previous-button,
.mat-datetimepicker-calendar-next-button,
.mat-datetimepicker-calendar-period-button {
  display: flex !important;
  align-items: center;
  min-height: 3.6rem !important;
  padding: 0.9rem !important;
}

.mat-datetimepicker-calendar-table {
  border-left: 1px solid #000000;
}

.mat-datetimepicker-calendar-table th {
  font-family: MarkPro-Bold, serif;
  color: #000000;
  font-size: 1.35rem !important;
}

.mat-datetimepicker-calendar-table td {
  color: rgba(18, 19, 21, 0.5);
  font-family: MarkPro, serif;
  font-size: 1.27rem;
}

.mat-datetimepicker-calendar-body-cell.mat-datetimepicker-calendar-body-active .mat-datetimepicker-calendar-body-cell-content {
  border-radius: 0.38rem;
  background-color: #111111 !important;
  color: #FFB64C;
}

.mat-datetimepicker-calendar-body-cell:hover .mat-datetimepicker-calendar-body-cell-content {
  border-radius: 0.38rem;
  background-color: #111111 !important;
  color: #FFB64C;
}

.mat-datetimepicker-calendar-body-cell.mat-datetimepicker-calendar-body-disabled .mat-datetimepicker-calendar-body-cell-content {
  text-decoration: line-through;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}


.mat-select-value-text,
.mat-option-text {
  font-family: MarkPro, serif;
}

.lang-ja .mat-select-value-text,
.lang-ja .mat-option-text {
  font-family: NotoSansJP-Regular, serif;
}

.company-list-table-action-item:hover {
  background: #FFB64C !important;
}

.cdk-overlay-pane .language-selector-options-list {
  padding: unset !important;
  border-radius: 0.5rem !important;
  transform: translate(-30px, 0) !important;
}

.language-selector-options-list .mat-mdc-option {
  min-height: unset !important;
  height: 4rem !important;
}

.language-selector-options-list .mdc-list-item--selected {
  background: #000000 !important;
}

.language-selector-options-list .mdc-list-item--selected .mdc-list-item__primary-text {
  color: #FFFFFF !important;
}

.cdk-overlay-pane {
  width: unset !important;
}

.mdc-list-item__primary-text {
  font-size: 1.5rem !important;
}

.mat-mdc-form-field-required-marker {
  color: #FF0000;
}

/******************************
******************************
******************************
        MEDIA QUERIES
*******************************
*******************************
*******************************/

@media (max-width: 400px) {
  /******************************
            Dialog Box
  *******************************/
  .dialog {
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

@media (max-width: 1439px) {
  html {
    font-size: 8px;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 2560px) and (max-width: 3439px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 3440px) {
  html {
    font-size: 20px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  overflow-y: scroll;
  background: rgba(217, 217, 217, 0.6);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 20px;
}
